import React, { useState, useEffect } from "react";
import { Container, Button, Typography, CircularProgress, Snackbar, Checkbox, Divider } from "@material-ui/core";
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as lodash from 'lodash';

export const ManageAccessRequests = () => {
    const GET_ACCESS_REQUESTS = gql`
        query GetAccessRequests($id: Int, $state: Boolean) {
            accessRequests(id: $id, state: $state) {
                id,
                reasonForRequest,
                requestDate,
                user {
                    email
                },
                requestedCompanies {
                    id,
                    name,
                    state
                }
            }
        }
    `;

    const UPDATE_ACCESS_REQUEST = gql`
        mutation UpdateAccessRequest($requestId: Int!, $state: Boolean!, $userCompanyList: [UserCompanyInputType]) {
            updateAccessRequest(requestID: $requestId, state: $state, userCompanyList: $userCompanyList) {
                id
            }
        }
    `;

    const { loading, error, refetch } = useQuery(GET_ACCESS_REQUESTS, { onCompleted: data => { setAccessRequests(data.accessRequests) } });
    const [companyDialogOpen, setCompanyDialogOpen] = useState(false);
    const [selectedAccessRequest, setSelectedAccessRequest] = useState();
    const [accessRequests, setAccessRequests] = useState();
    const [updateAccessRequestMutation] = useMutation(UPDATE_ACCESS_REQUEST);
    useEffect(() => {
        refetch(GET_ACCESS_REQUESTS);
    })
    const companyIsChecked = (id) => {
        if (accessRequests.find(x => x.id === selectedAccessRequest).requestedCompanies.find(c => c.id === id).state) {
            return true;
        }
        else {
            return false;
        }
    }

    const onCompanyCheckChange = (id, checked) => {
        var accessRequestsCopy = lodash.cloneDeep(accessRequests);
        accessRequestsCopy.find(x => x.id === selectedAccessRequest).requestedCompanies.find(c => c.id === id).state = checked;
        setAccessRequests(accessRequestsCopy)
    }

    const onRequestAction = (id, approved) => {
        var requestedCompanies = lodash.cloneDeep(accessRequests).find(x => x.id === id).requestedCompanies;
        requestedCompanies.forEach(function (v) { delete v.__typename });
        console.log(requestedCompanies);
        updateAccessRequestMutation({ variables: { requestId: id, state: approved, userCompanyList: requestedCompanies } })
            .then(e => {
                var accessRequestsCopy = lodash.cloneDeep(accessRequests);
                accessRequestsCopy.find(x => x.id === id).actioned = true;
                accessRequestsCopy.find(x => x.id === id).approved = approved;

                setAccessRequests(accessRequestsCopy);
            })
            .catch(e => {
                var accessRequestsCopy = lodash.cloneDeep(accessRequests);
                accessRequestsCopy.find(x => x.id === id).error = true;

                setAccessRequests(accessRequestsCopy);
            });
    }

    return (
        <Container maxWidth={'xl'} disableGutters={true}>
            <Typography variant="h4">Manage Access Requests</Typography>
            {!loading && accessRequests &&
                <React.Fragment>
                    <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Reason For Request</TableCell>
                                    <TableCell>Requested Date</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {accessRequests.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{item.user.email}</TableCell>
                                            <TableCell>{item.reasonForRequest}</TableCell>
                                            <TableCell>{item.requestDate}</TableCell>
                                            <TableCell>
                                                <Button
                                                    color="primary"
                                                    onClick={() => { setSelectedAccessRequest(item.id); setCompanyDialogOpen(true); }}
                                                >View Requested Companies</Button>
                                            </TableCell>
                                            <TableCell>
                                                {item.error ?
                                                    <Typography variant="subtitle1">AN ERROR OCCURED</Typography>
                                                    :
                                                    item.actioned ?
                                                        item.approved ?
                                                            <Typography variant="subtitle1">APPROVED</Typography>
                                                            :
                                                            <Typography variant="subtitle1">DENIED</Typography>
                                                        :
                                                        <React.Fragment>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => onRequestAction(item.id, true)}
                                                            >Approve</Button>
                                                            <span style={{ marginRight: '15px' }}></span>
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                onClick={() => onRequestAction(item.id, false)}
                                                            >Deny</Button>
                                                        </React.Fragment>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog open={companyDialogOpen} onClose={() => setCompanyDialogOpen(false)}>
                        <DialogTitle style={{ display: 'inline', paddingBottom: '5px' }}>
                            Requested Companies
                            {accessRequests.find(x => x.id === selectedAccessRequest) &&
                                <Typography variant="subtitle1">{accessRequests.find(x => x.id === selectedAccessRequest).user.email}</Typography>
                            }
                        </DialogTitle>
                        <DialogContent>
                            {accessRequests.find(x => x.id === selectedAccessRequest) && accessRequests.find(x => x.id === selectedAccessRequest).requestedCompanies &&
                                <React.Fragment>
                                    {accessRequests.find(x => x.id === selectedAccessRequest).requestedCompanies !== null && accessRequests.find(x => x.id === selectedAccessRequest).requestedCompanies.map((item) => {
                                        return (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={companyIsChecked(item.id)}
                                                        onChange={(e) => onCompanyCheckChange(item.id, e.target.checked)}
                                                    />
                                                }
                                                label={item.name}
                                            />
                                        )
                                    })}
                                </React.Fragment>
                            }
                            <DialogActions>
                                <Button color="primary" onClick={() => setCompanyDialogOpen(false)}>Close</Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                </React.Fragment>
            }
        </Container>
    );
}