import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, Typography, CircularProgress } from "@material-ui/core";
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import { ModerateFileWindow } from './ModerateFileWindow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import * as lodash from 'lodash';

export const ModerateFiles = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(1, 2),
        },
        fileRow: {
            '&:hover': {
                backgroundColor: `#E8E8E8`,
                border: `1px solid ${theme.palette.primary.main}`,
            }
        },
        addFileButton: {
            right: '48px',
            position: 'absolute'
        },
        cards: {
            paddingRight: '50px'
        }
    }));

    const classes = useStyles();

    const GET_FILES = gql`
        query GetFiles($approved: Boolean) {
            file(approved: $approved) {
                id
                companyId
                fileName
                dateUploaded
                expirationDate
                company {
                  name
                }
                user {
                  email
                }
            }
        }
    `;

    const APPROVE_FILE = gql`
        mutation ApproveFile($fileId: Int!, $approved: Boolean!) {
            approveFile(fileId: $fileId, approved: $approved) {
                approved
            }
        }
    `;

    const [approveFileMutation] = useMutation(APPROVE_FILE);

    const { loading, error, data, refetch } = useQuery(GET_FILES, { variables: { approved: false } });
    const [checkedFiles, setCheckedFiles] = useState(false);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [files, setFiles] = useState('');

    const [showModerateFileWindow, setShowModerateFileWindow] = useState(false);

    const [selectedFileId, setSelectedFileId] = useState();

    useEffect(() => {
        if (data) {
            setFiles(data.file);
        }
        refetch(GET_FILES);
    }, [data]);

    const onFileRowClick = (fileId) => {
        setSelectedFileId(fileId, setShowModerateFileWindow(true));
    }

    const onCloseModerateFileWindow = () => {
        setShowModerateFileWindow(false);
        setFiles([]);
        refetch();
    }

    const onApproveClick = (fileId, approved) => {
        var filesCopy = lodash.cloneDeep(files);
        filesCopy.find(x => x.id === fileId).loading = true;
        approveFileMutation({ variables: { fileId: fileId, approved: approved } }).then(r => {

            filesCopy.find(x => x.id === fileId).processed = true;
            filesCopy.find(x => x.id === fileId).approved = approved;
            filesCopy.find(x => x.id === fileId).loading = false;
            setFiles(filesCopy);
        })
            .catch(x => {
                filesCopy.find(x => x.id === fileId).loading = false;
                filesCopy.find(x => x.id === fileId).error = false;
            });
    }

    return (
        <Container maxWidth={false} disableGutters={true}>
            <Typography variant="h5">Moderate Files</Typography>
            {loading ?
                <CircularProgress />
                :
                files.length > 0 ?
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>File Name</TableCell>
                                    <TableCell>Date Added</TableCell>
                                    <TableCell>Added By</TableCell>
                                    <TableCell>For Company</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files.map((item, index) => {
                                    return (
                                        <TableRow key={item.id} className={classes.fileRow}>
                                            <TableCell>{item.fileName}</TableCell>
                                            <TableCell>{item.dateUploaded}</TableCell>
                                            <TableCell>{item.user.email}</TableCell>
                                            <TableCell>{item.company.name}</TableCell>
                                            <TableCell>
                                                <OpenInNewIcon onClick={() => onFileRowClick(item.id)} style={{ cursor: 'pointer' }} />
                                            </TableCell>
                                            <TableCell>
                                                {item.loading ?
                                                    <CircularProgress />
                                                    :
                                                    item.processed ?
                                                        item.approved ?
                                                            <Typography variant="subtitle1">APPROVED</Typography>
                                                            :
                                                            <Typography variant="subtitle1">REJECTED</Typography>
                                                        :
                                                        <React.Fragment>
                                                            <Button variant="contained" color="primary" style={{ marginRight: '10px' }} onClick={() => onApproveClick(item.id, true)}>Approve</Button>
                                                            <Button variant="outlined" color="primary" onClick={() => onApproveClick(item.id, false)}>Reject</Button>
                                                        </React.Fragment>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant="subtitle1">No Files Available</Typography>
            }
            {selectedFileId &&
                <ModerateFileWindow isOpen={showModerateFileWindow} setIsOpen={onCloseModerateFileWindow} fileId={selectedFileId} />
            }
        </Container>
    )
}