import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { Container, Button, Typography, TextField, Paper, Checkbox, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from "@material-ui/core";
import gql from 'graphql-tag';

export const ManageNewCompanyRequests = () => {


    const GET_Requested_COMPANIES = gql`
query GetRequestedCompanies {
    requestedCompany {
        id
        requestedCompany            
        reason
        requestedBy
        requestedDate
    }
}
`;

    const UPDATE_ACCESS_REQUEST = gql`
mutation ApproveRequestedCompany($requestId: Int!, $state: Boolean!) {
    approveRequestedCompany(requestId: $requestId, state: $state) {
        id
    }
}
`;

    const [requests, setRequests] = useState([]);
    const { loading, error, refetch } = useQuery(GET_Requested_COMPANIES, { onCompleted: data => { setRequests(data.requestedCompany) } });
    useEffect(() => {
        refetch(GET_Requested_COMPANIES);
    })

    const [updateAccessRequestMutation] = useMutation(UPDATE_ACCESS_REQUEST);
    const onRequestAction = (id, approved) => {

        updateAccessRequestMutation({ variables: { requestId: id, state: approved } })
            .then(e => {
                var accessRequestsCopy = [...requests];
                accessRequestsCopy.find(x => x.id === id).actioned = true;
                accessRequestsCopy.find(x => x.id === id).approved = approved;
                setRequests(accessRequestsCopy);
            })
            .catch(e => {
                var accessRequestsCopy = [...requests];
                accessRequestsCopy.find(x => x.id === id).error = true;
                setRequests(accessRequestsCopy);
            });
    }


    return (
        <Container maxWidth={'xl'} disableGutters={true}>
            <Typography variant="h4">Manage New Company Requests</Typography>
            {requests &&
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Requested Company</TableCell>
                                <TableCell>Reason</TableCell>
                                <TableCell>Requested By</TableCell>
                                <TableCell>Requested Date</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {requests.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{item.requestedCompany}</TableCell>
                                        <TableCell>{item.reason}</TableCell>
                                        <TableCell>{item.requestedBy}</TableCell>
                                        <TableCell>{item.requestedDate}</TableCell>
                                        <TableCell>
                                            {item.error ?
                                                <Typography variant="subtitle1">AN ERROR OCCURED</Typography>
                                                :
                                                item.actioned ?
                                                    item.approved ?
                                                        <Typography variant="subtitle1">APPROVED</Typography>
                                                        :
                                                        <Typography variant="subtitle1">DENIED</Typography>
                                                    :
                                                    <React.Fragment>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => onRequestAction(item.id, true)}
                                                        >Approve</Button>
                                                        <span style={{ marginRight: '15px' }}></span>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={() => onRequestAction(item.id, false)}
                                                        >Deny</Button>
                                                    </React.Fragment>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Container>
    )
}