export const getBaseURL = () => {
    if (window.location.hostname.includes("localhost"))
        return "https://localhost:5001/api/";
    else if (window.location.hostname.includes("kftp-beta.mining.komatsu"))
        return "https://kmc-kftp-api-beta.azurewebsites.net/api/";

    return "https://kmc-kftp-prod-api.azurewebsites.net/api/";
}

export const getBaseHubsURL = () => {
    if (window.location.hostname.includes("localhost"))
        return "https://localhost:5001/hubs/";
    else if (window.location.hostname.includes("kftp-beta.mining.komatsu"))
        return "https://kmc-kftp-api-beta.azurewebsites.net/hubs/";

    return "https://kmc-kftp-prod-api.azurewebsites.net/hubs/";
}