import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Button, Grid, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import tutorialImage1 from './OneDriveTutorial1.png';
import tutorialImage2 from './OneDriveTutorial2.png';
import tutorialImage3 from './OneDriveTutorial3.png';
import tutorialImage4 from './OneDriveTutorial4.png';
import tutorialImage5 from './OneDriveTutorial5.png';
import tutorialImage6 from './OneDriveTutorial6.png';

export const SplashPage = () => {
    return (
        <div style={{ maxWidth: "1000px", marginLeft: "auto", marginRight: "auto" }} >
            <Typography variant="h5" component="div" style={{ marginBottom: "20px" }}>
                We are exploring more efficient and effective alternatives to the KFTP solution.
            </Typography>
            <Typography variant="h6" component="div" style={{ marginBottom: "10px" }}>
                Please consider whether KFTP is required to fully meet your needs or if OneDrive “Share” functionality provides an appropriate level of security and control for your respective use case.
            </Typography>
            <Typography variant="h6" component="div" style={{ marginBottom: "10px" }}>
                Please contact Daniel Schumacher (<a href="mailto: daniel.schumacher@global.komatsu">daniel.schumacher@global.komatsu</a>) with questions or thoughts.
            </Typography>
            <Accordion style={{ marginTop: "10px", marginBottom: "10px" }} >
                <AccordionSummary style={{ fontSize: "18px" }} >OneDrive sharing instructions</AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={4}>
                        <Grid item style={{ width: "33%", height: "auto" }}>
                            <Typography variant="h6" component="div">
                                Copy the file to share to the OneDrive - Komatsu directory.
                            </Typography>
                        </Grid>
                        <Grid item style={{ width: "67%" }}>
                            <img width={"100%"} src={tutorialImage1} />
                        </Grid>
                        <Grid item style={{ width: "33%" }}>
                            <Typography variant="h6" component="div">
                                The address bar should look similar to this:
                            </Typography>
                        </Grid>
                        <Grid item style={{ width: "67%" }}>
                            <img width={"100%"} src={tutorialImage2} />
                        </Grid>
                        <Grid item style={{ width: "33%" }}>
                            <Typography variant="h6" component="div">
                                Right click on the file to share and click Share.
                            </Typography>
                        </Grid>
                        <Grid item style={{ width: "67%" }}>
                            <img width={"100%"} src={tutorialImage3} />
                        </Grid>
                        <Grid item style={{ width: "33%" }}>
                            <Typography variant="h6" component="div">
                                Enter the email address of the person you would like to share it with.
                            </Typography>
                        </Grid>
                        <Grid item style={{ width: "67%" }}>
                            <img width={"100%"} src={tutorialImage4} />
                        </Grid>
                        <Grid item style={{ width: "33%" }}>
                            <Typography variant="h6" component="div">
                                Set the desired permissions and click "Apply".
                            </Typography>
                        </Grid>
                        <Grid item style={{ width: "67%" }}>
                            <img width={"100%"} src={tutorialImage5} />
                        </Grid>
                        <Grid item style={{ width: "33%" }}>
                            <Typography variant="h6" component="div">
                                Press send to share:
                            </Typography>
                        </Grid>
                        <Grid item style={{ width: "67%" }}>
                            <img width={"100%"} src={tutorialImage6} />
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                            <Typography variant="h6" component="div">
                                For further documentation, please refer to the official Microsoft documentation here: <a target={"_blank"} href='https://support.microsoft.com/en-us/office/share-files-with-onedrive-4e871e9a-4cb7-4c66-8b38-d2ee590532c2'>https://support.microsoft.com/en-us/office/share-files-with-onedrive-4e871e9a-4cb7-4c66-8b38-d2ee590532c2</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Button component={Link} button key={6} to='/files' color="primary">I understand, continue to KFTP</Button>
        </div>
    );
}