import React, { useState, useEffect } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import { IconButton/* , ListItemAvatar */ } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import klogo from "../../img/Komatsu_white.png";
import Typography from '@material-ui/core/Typography';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import Collapse from '@material-ui/core/Collapse';
import FolderIcon from '@material-ui/icons/Folder';
import PersonIcon from '@material-ui/icons/Person';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BusinessIcon from '@material-ui/icons/Business';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    navmenuimg: {
        "@media (max-width: 767px)": {
            maxWidth: "145px",
            height: "auto"
        },
    },
    navmenuh5: {
        "@media (max-width: 767px)": {
            fontSize: "12px !important",
            paddingLeft: "0 !important"
        },
    },
    navmenuh6: {
        "@media (max-width: 767px)": {
            fontSize: "12px !important"
        },
    },

    menuButton: {
        marginRight: theme.spacing(2),
        "@media (max-width: 767px)": {
            marginRight: "0px !important"
        },
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

const NavMenu = ({ classes, theme, isOpen, handleSetOpen, currentUser, hasAccess }) => {
    let history = useNavigate();
    const currentUserAuth = useSelector(state => state.auth);

    const [adminMenuOpen, setAdminMenuOpen] = useState(false);

    const [userAdmin, setUserAdmin] = useState(false);
    const [externalUserAdmin, setExternalUserAdmin] = useState(false);
    const [addExternalUser, setAddExternalUser] = useState(false);
    const [addAccessRequest, setAddAccessRequest] = useState(false);
    const [moderateFiles, setModerateFiles] = useState(false);
    const [manageCompanies, setManageCompanies] = useState(false);

    useEffect(() => {
        if (currentUserAuth.user.userPermissions !== undefined) {

            try {
                if (currentUserAuth.user.userPermissions.find(p => p.permission.permissionName === "Admin Users").state) {
                    setUserAdmin(true);
                }

                if (currentUserAuth.user.userPermissions.find(p => p.permission.permissionName === "File Moderator").state) {
                    setModerateFiles(true);
                }

                if (currentUserAuth.user.userPermissions.find(p => p.permission.permissionName === "Add External Users").state) {
                    setAddExternalUser(true);
                }

                if (currentUserAuth.user.userPermissions.find(p => p.permission.permissionName === "Admin Companies").state) {
                    setManageCompanies(true);
                }

                if (currentUserAuth.user.userPermissions.find(p => p.permission.permissionName === "Access State").state) {
                    setAddAccessRequest(true);
                }

                if (currentUserAuth.user.userPermissions.find(p => p.permission.permissionName === "Approve External Users").state) {
                    setExternalUserAdmin(true);
                }
            } catch (error) {
                console.log(error);
            }
        }
    });

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: isOpen,
                })}
                color="primary"
            >
                <Toolbar>
                    {hasAccess &&
                        <IconButton
                            color="inherit"
                            aria-label="open menu"
                            onClick={() => handleSetOpen(true)}
                            edge="start"
                            className={clsx(classes.menuButton, isOpen && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                    }
                    <img src={klogo} alt="logo" height="70px" className={classes.navmenuimg} style={{ cursor: "pointer" }} onClick={() => history.push("/")} />
                    <Typography variant="h5" color="inherit" className={classes.navmenuh5} style={{ paddingLeft: "10px", fontSize: '1.25vw' }}>
                        KFTP
                    </Typography>
                    {currentUserAuth.aadResponse &&
                        <Typography variant="h6" className={classes.navmenuh6} style={{ marginLeft: "auto", marginRight: '10px', fontSize: '1vw' }} color="inherit">{currentUserAuth.aadResponse.account.name}</Typography>
                    }
                </Toolbar>
            </AppBar>
            {hasAccess &&
                <span>
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={isOpen}
                        classes={{ paper: classes.drawerPaper }}
                    >
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={() => handleSetOpen(false)}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <Divider />
                        <List>
                            <ListItem component={Link} button key={1} to='/files' color="primary" onClick={() => handleSetOpen(false)}>
                                <ListItemIcon><FolderIcon /></ListItemIcon>
                                <ListItemText>Files</ListItemText>
                            </ListItem>
                            {addExternalUser &&
                                <ListItem component={Link} button key={2} to='/addexternaluser' color="primary" onClick={() => handleSetOpen(false)}>
                                    <ListItemIcon><AssignmentIndIcon /></ListItemIcon>
                                    <ListItemText>Add External User</ListItemText>
                                </ListItem>
                            }
                            {addAccessRequest &&
                                <ListItem component={Link} button key={10} to='/accessrequest' color="primary" onClick={() => handleSetOpen(false)}>
                                    <ListItemIcon><AssignmentIndIcon /></ListItemIcon>
                                    <ListItemText>Access Request</ListItemText>
                                </ListItem>
                            }
                        </List>
                        {(addExternalUser || moderateFiles || userAdmin || manageCompanies || externalUserAdmin) &&
                            <React.Fragment>
                                <List>
                                    <ListItem button key={9} color="primary" onClick={() => setAdminMenuOpen(!adminMenuOpen)}>
                                        <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
                                        <ListItemText>Administration</ListItemText>
                                        {adminMenuOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                </List>
                                <Collapse in={adminMenuOpen} timeout="auto" unmountOnExit>
                                    <List>
                                        {moderateFiles &&
                                            <ListItem component={Link} button key={3} to='/moderatefiles' color='primary' className={classes.nested} onClick={() => handleSetOpen(false)}>
                                                <ListItemIcon><VerifiedUserIcon /></ListItemIcon>
                                                <ListItemText>Moderate Files</ListItemText>
                                            </ListItem>
                                        }
                                        {userAdmin &&
                                            <React.Fragment>
                                                <ListItem component={Link} button key={4} to='/manageusers' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                                                    <ListItemIcon><PersonIcon /></ListItemIcon>
                                                    <ListItemText>Manage Users</ListItemText>
                                                </ListItem>
                                                <ListItem component={Link} button key={6} to='/manageaccessrequests' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                                                    <ListItemIcon><VpnKeyIcon /></ListItemIcon>
                                                    <ListItemText>Manage Access Requests</ListItemText>
                                                </ListItem>
                                                <ListItem component={Link} button key={8} to='/managenewcompanyrequests' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                                                    <ListItemIcon><BusinessIcon /></ListItemIcon>
                                                    <ListItemText>Manage New Company Requests</ListItemText>
                                                </ListItem>
                                            </React.Fragment>
                                        }
                                        {manageCompanies &&
                                            <ListItem component={Link} button key={5} to='/managecompanies' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                                                <ListItemIcon><BusinessIcon /></ListItemIcon>
                                                <ListItemText>Manage Companies</ListItemText>
                                            </ListItem>
                                        }
                                        {externalUserAdmin &&
                                            <ListItem component={Link} button key={7} to='/manageexternaluserrequests' color="primary" className={classes.nested} onClick={() => handleSetOpen(false)}>
                                                <ListItemIcon><PersonAddIcon /></ListItemIcon>
                                                <ListItemText>Manage External User Requests</ListItemText>
                                            </ListItem>
                                        }
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        }
                    </Drawer>
                </span>
            }
        </div>
    );
}

export default withStyles(styles, { withTheme: true })(NavMenu);