import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import { registerLicense } from '@syncfusion/ej2-base';
import './material.css';
import { createRoot } from 'react-dom/client';

registerLicense("ORg4AjUWIQA/Gnt2VVhhQlFac15JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkBjW31ec3dXR2VcU0E=");
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
