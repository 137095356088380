import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import store from "./store";
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { Provider } from "react-redux";
import { authProvider } from './authProvider';
import Layout from './components/Layout/Layout';
import { ApolloProvider } from '@apollo/client';
import { GraphQlClient } from './apolloClient';
import { Files } from './components/miscellaneous/Files';
import { ManageUsers } from './components/admin/ManageUsers';
import { ModerateFiles } from './components/admin/ModerateFiles';
import { ManageCompanies } from './components/admin/ManageCompanies';
import { AccessRequest } from './components/AccessRequest';
import { ManageAccessRequests } from './components/admin/ManageAccessRequests';
import { ExternalUserRequest } from './components/ExternalUserRequest';
import { ManageExternalUserRequests } from './components/admin/ManageExternalUserRequests';
import { ManageNewCompanyRequests } from './components/admin/ManageNewCompanyRequests';
import { SplashPage } from './components/SplashPage/SplashPage';

const App = () => {
    return (
        <Provider store={store}>
            <AzureAD provider={authProvider} forceLogin={true} reduxStore={store}>
                {({ authenticationState, error, login, accountInfo }) => {
                    {
                        if (authenticationState === AuthenticationState.Authenticated) {
                            return (
                                <BrowserRouter>
                                    <ApolloProvider client={GraphQlClient(accountInfo.jwtIdToken)}>
                                        <Layout accountInfo={accountInfo}>
                                            <Routes>
                                                <Route exact path="/splashpage" element={ <SplashPage />}/>
                                                <Route exact path="/" element={<Files />} />
                                                <Route exact path="/files" element={<Files />} />
                                                <Route exact path="/manageusers" element={<ManageUsers />} />
                                                <Route exact path="/moderatefiles" element={<ModerateFiles />} />
                                                <Route exact path="/managecompanies" element={<ManageCompanies />} />
                                                <Route exact path="/accessrequest" element={<AccessRequest email={accountInfo.account.userName} />}  />
                                                <Route exact path="/manageaccessrequests" element={<ManageAccessRequests />}  />
                                                <Route exact path="/addexternaluser" element={<ExternalUserRequest />}  />
                                                <Route exact path="/manageexternaluserrequests" element={<ManageExternalUserRequests />} />
                                                <Route exact path="/managenewcompanyrequests" element={<ManageNewCompanyRequests />}  />
                                            </Routes>
                                        </Layout>
                                    </ApolloProvider>
                                </BrowserRouter>
                            );
                        }
                        else {
                            return (
                                <p>
                                    <span>You will be redirected shortly to log in</span>
                                </p>
                            );
                        }
                    }
                }
                }
            </AzureAD>
        </Provider>
    );
}

export default App;
