const initialNavState = {
    shelfNavItems: [],
    badgeState: {
        active: false,
        counts: {},
    },
    
}
export const navReducer = (state = initialNavState, action) => {
    
    switch (action.type) {
        
        case 'SET_NAV_STATE':

            return {
                ...state,
                shelfNavItems: action.payload
            }
        case 'SET_BADGE_STATE':  
            
            const counts = { ...state.badgeState.counts, ...action.payload.counts };  
            const active = Object.keys(counts).some(key => counts[key] > 0);     
             
            return {
                ...state,
                badgeState: {
                    ...state.badgeState,                    
                    active,
                    counts
                },
                
            }
        default:
            return state;
    }
}
