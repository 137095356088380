import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, CardContent, Typography, Paper, TextField, Card, Grid, CircularProgress } from "@material-ui/core";
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { UserProfile } from './UserProfile';

export const ManageUsers = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(1, 2),
        },
        fileCard: {
            width: '300px',
            height: '50px',
            display: 'inline-block',
            marginRight: '15px',
            marginBottom: '15px',
            '&:hover': {
                border: `1px solid ${theme.palette.primary.main}`,
                cursor: 'pointer'
            }
        }
    }));

    const classes = useStyles();

    const SEARCH_USER = gql`
        query GetUserByEmail($email: String) {
            user(email: $email) {
            guid
            email
            }
        }
    `;

    const [userSearch, userSearchQueryResults] = useLazyQuery(SEARCH_USER, { onCompleted: data => { setIsLoading(false); } });
    const [searchText, setSearchText] = useState("");

    const [userProfileIsOpen, setUserProfileIsOpen] = useState(false);

    const handleUserSearch = () => {
        if (searchText.length > 0) {
            setIsLoading(true);
            userSearch({ variables: { email: searchText } });
        }
    }

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    }

    const handleUserProfileOpen = (userId) => {
        setSelectedUser(userId);
        setUserProfileIsOpen(true);
    }

    const [selectedUser, setSelectedUser] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    return (
        <Container maxWidth={'xl'} disableGutters={true}>
            <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                <Typography variant="h4" style={{ flex: 1 }}>Manage Users</Typography>
            </Grid>

            <Paper className={classes.root} style={{ marginTop: '10px' }}>
                <TextField
                    color="primary"
                    variant="outlined"
                    label="User Search"
                    fullWidth
                    style={{ marginTop: '10px' }}
                    value={searchText}
                    onChange={handleSearchTextChange}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleUserSearch();
                        }
                    }}
                />
                {isLoading ?
                    <CircularProgress />
                    :
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                        onClick={handleUserSearch}
                    >Search</Button>
                }
            </Paper>
            {userSearchQueryResults && userSearchQueryResults.data && userSearchQueryResults.data.user &&
                <div style={{ paddingTop: '10px' }}>
                    {userSearchQueryResults.data.user.length > 0 && userSearchQueryResults.data.user.map((item, index) => {
                        return (
                            <Card key={index} className={classes.fileCard} onClick={() => handleUserProfileOpen(item.guid)}>
                                <CardContent>
                                    {item.email}
                                </CardContent>
                            </Card>
                        )
                    })}
                    {userSearchQueryResults.data.user.length === 0 &&
                        <Typography variant="subtitle1">No Users Found</Typography>
                    }
                </div>
            }
            <UserProfile userId={selectedUser} isOpen={userProfileIsOpen} handleSetOpen={setUserProfileIsOpen} />
        </Container>
    );
}