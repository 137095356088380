import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { Container, Button, Typography, TextField, Paper, makeStyles, Snackbar, CircularProgress } from "@material-ui/core";
import gql from 'graphql-tag';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';

export const ExternalUserRequest = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(2, 2),
        }
    }));

    const classes = useStyles();

    const GET_COMPANIES = gql`
        query GetCompanies {
            company {
                name
                id
                state
            }
        }
    `;
    const GET_USERS = gql`
    query GetUsers {
        user {
          email
          guid
        }
    }
`;
    const ADD_EXTERNAL_USER_REQUEST = gql`
        mutation AddExternalUser($email: String!, $companyId: Int!) {
            addExternalUser(email: $email, companyId: $companyId) {
                id
            }
        }
    `;
    const [UserList, setUserList] = useState([]);
    const { refetch, Users } = useQuery(GET_USERS, {
        onCompleted: data => {

            setUserList(data.user)

        }
    });

    const { data: companies } = useQuery(GET_COMPANIES);
    const [requestedCompany, setRequestedCompany] = useState();
    const [email, setEmail] = useState("");
    const [missingEmail, setMissingEmail] = useState(false);
    const [missingCompany, setMissingCompany] = useState('');
    const [addExternalUserMutation] = useMutation(ADD_EXTERNAL_USER_REQUEST);
    const [loading, setLoading] = useState(false);

    const handleAutoComplete = (event, value) => {
        console.log(value);
        if (value) {
            setRequestedCompany(value.id);
            setMissingCompany("");
        }

    }

    const onRequestSubmit = () => {
        if (email && requestedCompany) {
            if (UserList.some(e => e.email.toLowerCase() === email.toLowerCase())) {
                setErrorSnackBarText("User Already Exists");
                setErrorSnackBarOpen(true);
            } else {
                setLoading(true);
                addExternalUserMutation({ variables: { email: email, companyId: requestedCompany } }).then(x => { setSnackBarOpen(true); setLoading(false); }).catch(x => { setErrorSnackBarText("Error Occured Submitting Request"); setErrorSnackBarOpen(true); setLoading(false); });

            }
        }
        else if (email === "")
            setMissingEmail(true);
        else if (requestedCompany === undefined)
            setMissingCompany("Please Select Company")

    }

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
    const [errorSnackBarText, setErrorSnackBarText] = useState("");

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorSnackBarOpen(false);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    }

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
        <Container maxWidth={'xl'} disableGutters={true}>
            <Typography variant="h4">Add External User</Typography>

            <Paper className={classes.root}>
                <React.Fragment>
                    <TextField
                        color="primary"
                        variant="outlined"
                        label="User Email Address"
                        fullWidth
                        required
                        onChange={(e) => { setEmail(e.target.value); if (e.target.value) { setMissingEmail(false); } }}
                        helperText={missingEmail ? "Required" : ""}
                        error={missingEmail}
                    />
                    {companies &&
                        <Autocomplete
                            style={{ marginTop: '20px', marginBottom: '10px' }}
                            options={companies.company}
                            getOptionLabel={(option) => option.name}
                            onChange={handleAutoComplete}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Company"
                                    placeholder="Company Name"
                                    error={missingCompany.length > 0}
                                    helperText={missingCompany}
                                />
                            )}
                        />
                    }
                    {loading ?
                        <CircularProgress />
                        :
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => onRequestSubmit()}
                        >Submit</Button>
                    }


                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={5000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert onClose={handleSnackbarClose} severity="success">
                            Request has been submitted
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={errorSnackBarOpen}
                        autoHideDuration={5000}
                        onClose={handleErrorSnackbarClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert onClose={handleErrorSnackbarClose} severity="error">
                            {errorSnackBarText}
                        </Alert>
                    </Snackbar>
                </React.Fragment>
            </Paper>
        </Container>
    )
}