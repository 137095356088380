import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, Typography, CircularProgress } from "@material-ui/core";
import gql from 'graphql-tag';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Axios from 'axios';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getBaseURL } from "../../baseURL";
import { useSelector } from "react-redux";
import Alert from '@material-ui/lab/Alert';
import { sizing } from '@material-ui/system';

export const ModerateFileWindow = ({isOpen, setIsOpen, fileId}) => {
    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(1, 2),
        },
        fileListFile: {
            '&:hover': {
                backgroundColor: `#E8E8E8`,
                border: `1px solid ${theme.palette.primary.main}`,
                cursor: 'pointer',
                borderRadius: '5px'
            }
        },
        downloadIcon: {
            float: 'right'
        }
    }));

    const classes = useStyles();

    const GET_FILES = gql`
        query GetFileById($fileId: Int, $approved: Boolean) {
            file(fileId: $fileId, approved: $approved) {
                id
                companyId
                fileName
                dateUploaded
                expirationDate
                company {
                    name
                }
                user {
                    email
                }
                zipFileContents {
                    id
                    linkedFileId
                        fileName
                        mimeType
                }
            }
        }
    `;

    const APPROVE_FILE = gql`
        mutation ApproveFile($fileId: Int!, $approved: Boolean!) {
            approveFile(fileId: $fileId, approved: $approved) {
                approved
            }
        }
    `;

    const {data, loading, error, refetch} = useQuery(GET_FILES, {variables: {fileId: fileId, approved: false}});

    const [file, setFile] = useState();
    const [zipFileContents, setZipFileContents] = useState([]);

    const [approveFileMutation] = useMutation(APPROVE_FILE);
    const [processedApproval, setProcessedApproval] = useState(false);
    const [fileApproved, setFileApproved] = useState(false);
    const [fileIsDownloading, setFileIsDownloading] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const currentUser = useSelector(state => state.auth);

    const config = {
        headers: {'Authorization': "Bearer " + currentUser.aadResponse.jwtIdToken}
    };

    useEffect(() => {
        setIsLoading(true);

        refetch({variables: {fileId: fileId, approved: false}}).then(x => {
            setProcessedApproval(false);
            setFileApproved(false);
            if(x.data && x.data.file[0]) {
                setFile(x.data.file[0]);
            }
            setIsLoading(false);
        })
        .catch(x => {
            setIsLoading(false);
        });
    }, [fileId]);

    const onZipFileClick = (zipFileId) => {
        Axios.get(`${getBaseURL()}files/filedownloadfromzip/${zipFileId}`, config)
        .then((response) => {
            var byteArray = base64ToArrayBuffer(response.data.byteArray);

            const blob = new Blob([byteArray], { type: response.data.mimeType});

            if(window.navigator && window.navigator.msSaveOrOpenBlob) {
                
                window.navigator.msSaveOrOpenBlob(blob, response.data.fileName);
            }
            else {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                var url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = response.data.fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        })
    }

    const onApproveClick = (approved) => {
        approveFileMutation({variables: {fileId: fileId, approved: approved}}).then(r => setProcessedApproval(true), setFileApproved(approved));
    }

    const onDownloadClick = () => {
        setFileIsDownloading(true);
        Axios.get(`${getBaseURL()}files/filedownload/${fileId}`, config)
        .then((response) => {
            var byteArray = base64ToArrayBuffer(response.data.byteArray);

            const blob = new Blob([byteArray], { type: response.data.mimeType});

            if(window.navigator && window.navigator.msSaveOrOpenBlob) {
                
                window.navigator.msSaveOrOpenBlob(blob, response.data.fileName);
            }
            else {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                var url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = response.data.fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }

            setFileIsDownloading(false);
        })
        .catch((error) => {
            setFileIsDownloading(false);
        });
    }

    function base64ToArrayBuffer(base64) {
        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (var i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
        }

        return bytes;
    }

    return(
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} style={{overflow: 'hidden'}} minHeight={100}>
            {file ?
                isLoading ?
                    <CircularProgress style={{padding: '100px'}}/>
                :
                <React.Fragment>
                    <DialogTitle>
                        File: {file.fileName}
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle1">Uploaded By: {file.user.email}</Typography>
                        <Typography variant="subtitle1">Uploaded Date: {file.dateUploaded}</Typography>
                        <Typography variant="subtitle1">For Company: {file.company.name}</Typography>

                        {file.zipFileContents.length > 0 &&
                            <React.Fragment>
                                <Typography variant="h6" style={{paddingTop: '20px'}}>Compressed File Contents</Typography>
                                {zipFileContents.map((item, index) => {
                                return(
                                    <Typography variant="subtitle1" className={classes.fileListFile} onClick={() => onZipFileClick(item.id)}><span style={{margin: '5px'}}>{item.fileName}<GetAppIcon className={classes.downloadIcon}/></span></Typography>
                                )
                            })}
                            </React.Fragment>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="contained" disabled={fileIsDownloading} onClick={onDownloadClick}>
                            Download
                            {fileIsDownloading &&
                                <CircularProgress size={25} />
                            }
                        </Button>
                        {processedApproval ?
                            fileApproved ?
                                <Alert severity="success">File Approved</Alert>
                            :
                                <Alert severity="success">File Rejected</Alert>
                            
                        :
                            <React.Fragment>
                                <Button variant="contained" color="primary" onClick={() => onApproveClick(true)}>Approve</Button>
                                <Button variant="outlined" color="primary" onClick={() => onApproveClick(false)}>Reject</Button>
                            </React.Fragment> 
                        }
                        
                        <Button variant="text" color="primary" style={{marginLeft: '10px'}} onClick={() => setIsOpen(false)}>Close</Button>
                    </DialogActions>
                </React.Fragment>
                
            :
                <CircularProgress />
            }
            
        </Dialog>
    );
}