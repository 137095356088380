import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, CardContent, Typography, CircularProgress, Snackbar, Select, MenuItem, Grid } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import { useSelector } from "react-redux";
import { useLazyQuery } from '@apollo/client';
import Grow from '@material-ui/core/Grow';
import Axios from 'axios';
import { getBaseURL } from "../../baseURL";
import MuiAlert from '@material-ui/lab/Alert';
import gql from 'graphql-tag';
import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import CancelImage from "../../img/cancel.png";
import "../miscellaneous/File.css";
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import * as lodash from 'lodash';

export const Files = () => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: theme.spacing(1, 2),
        },
        fileCard: {
            width: '32%',
            height: 'auto',
            display: 'inline-block',
            marginBottom: '30px',
            '&:hover': {
                border: `1px solid ${theme.palette.primary.main}`
            }, "@media (max-width: 767px)": {
                width:
                    "100%",
                paddingRight: "0",
                height: "auto"
            },
        },
        griditem: {
            "@media (max-width: 767px)": {
                width:
                    "100%",
                marginTop: "15px"
            },
        },
        addFileButton: {
            right: '48px',
            position: 'absolute'
        },
        cards: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginTop: "20px",
            "@media (max-width: 767px)": {
                width:
                    "100%"
            },
        },
        cardLoading: {
            backgroundColor: '#cccccc',
            color: '#ffffff',

        },
        cardNotLoading: {
            backgroundColor: '#ffffff',
            color: '#000000',
            border: '1px solid transparent'
        },

    }));

    const classes = useStyles();
    const currentUser = useSelector(state => state.auth);
    const [userCompanies, setUserCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState();
    const [companyFiles, setCompanyFiles] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [useremail, setuserEmail] = useState('');
    const [UserId, setUserId] = useState('');
    const [loadingCompanies, setLoadingCompanies] = useState(false);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [cancelfile, setCancelFileUploading] = useState(false);

    const config = {
        headers: { 'Authorization': "Bearer " + currentUser.aadResponse.jwtIdToken },
        timeout: 24000000
    };

    const GET_USER_COMPANIES = gql`
        query GetUserCompanies($userId: String, $state: Boolean) {
            userCompanies(userId: $userId, state: $state) {
                state
                company {
                    id
                    name
                }
            }
        }
        `;

    const GET_COMPANY_FILES = gql`
        query GetUserFiles($companyId: Int, $expirationStartDate: DateTime) {
            file(companyId: $companyId, expirationStartDate: $expirationStartDate) {
                id
                companyId
                fileName
                dateUploaded
                expirationDate
                downloading
            }
        }
    `;
    const [showProgress, setShowProgress] = useState(false)
    const [counter, setCounter] = useState(1)
    const [fileToBeUpload, setFileToBeUpload] = useState({})
    const [progress, setProgress] = useState(0)
    const [fileGuid, setFileGuid] = useState([])
    const [fileName, setfileName] = useState("")
    const [fileType, setfileType] = useState("")
    const [fileSize, setFileSize] = useState(0)
    const [chunkCount, setChunkCount] = useState(0)
    const [getUserCompanies, userCompaniesQueryResults] = useLazyQuery(GET_USER_COMPANIES);
    const [getCompanyFiles, companyFilesQueryResults] = useLazyQuery(GET_COMPANY_FILES);
    const [checkedCompanies, setCheckedCompanies] = useState(false);
    const [checkedCompanyFiles, setCheckedCompanyFiles] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
    const [errorSnackBarText, setErrorSnackBarText] = useState("");
    const [uploadFiles, setUploadFiles] = useState(false);
    const [files, setFiles] = useState([]);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorSnackBarOpen(false);

    }
    
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);

    }

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleCompanyChange = (event) => {
        setSelectedCompany(event.target.value);
        setLoadingFiles(true);
        companyFilesQueryResults.refetch({ companyId: event.target.value, expirationStartDate: new Date() })
            .then(x => { setLoadingFiles(false); });
    }

    const handleDownloadClick = (fileId) => {
        let filesArray = lodash.cloneDeep(companyFiles);

        if (!filesArray.find(x => x.id === fileId).downloading) {
            filesArray.find(x => x.id === fileId).downloading = true;

            setCompanyFiles(filesArray);

            Axios.get(`${getBaseURL()}files/filedownload/${fileId}`, config)
                .then((response) => {         
                    if(response.data.downloadResponse!==""){                     
                        const link = document.createElement('a');
                        link.href = response.data.downloadResponse;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);            
                     
                     }else{
                        setErrorSnackBarText("An error occured downloading file");
                        setErrorSnackBarOpen(true);
                     }

                    filesArray = lodash.cloneDeep(companyFiles);

                    filesArray.find(x => x.id === fileId).downloading = false;
                    console.log(JSON.stringify(response));

                    setCompanyFiles(filesArray);
                })
                .catch((response) => {
                    setErrorSnackBarText("An error occured downloading file");
                    setErrorSnackBarOpen(true);

                    filesArray = lodash.cloneDeep(companyFiles);
                    filesArray.find(x => x.id === fileId).downloading = false;
                    setCompanyFiles(filesArray);
                })
        }
        else {
            setErrorSnackBarText("File Is Already Downloading. Please Wait.");
            setErrorSnackBarOpen(true);
        }
    }

    useEffect(() => {
        if (userCompanies && userCompanies.length < 1 && !checkedCompanies) {
            getUserCompanies({ variables: { userId: currentUser.aadResponse.account.accountIdentifier, state: true } });
            setCheckedCompanies(true);
        }

        if (userCompaniesQueryResults.data && userCompaniesQueryResults.data.userCompanies !== userCompanies) {
            setUserCompanies(userCompaniesQueryResults.data.userCompanies);

            if (userCompaniesQueryResults.data.userCompanies.length > 0) {
                setSelectedCompany(userCompaniesQueryResults.data.userCompanies[0].company.id);
            }
        }

        if (selectedCompany && companyFilesQueryResults && companyFilesQueryResults.data && companyFilesQueryResults.data.file != companyFiles) {
            if (!companyFilesQueryResults.loading) {
                setCompanyFiles(companyFilesQueryResults.data.file);
            }
        }

        if (userCompanies && userCompanies.length > 0 && companyFiles.length < 1 && selectedCompany && !checkedCompanyFiles) {
            getCompanyFiles({ variables: { companyId: selectedCompany, expirationStartDate: new Date() } });
            setCheckedCompanyFiles(true);

            if (companyFilesQueryResults.data && companyFilesQueryResults.data.file) {
                setCompanyFiles(companyFilesQueryResults.data.file);
            }
        }
    });

    useEffect(() => {
        if (currentUser && currentUser.user.userPermissions) {
            try {
                if (currentUser.user.userPermissions.find(p => p.permission.permissionName === "Upload Files").state) {
                    setUploadFiles(true);
                    setUserId(currentUser.aadResponse.account.accountIdentifier)
                    setuserEmail(currentUser.aadResponse.account.userName)
                    console.log("upload files true")
                }
            }
            catch { }
        }
    }, [currentUser]);

    const asyncSettings = {
        saveUrl: `${getBaseURL()}files/fileupload/${selectedCompany}`,
        chunkSize: 25000000
    }

    const onFilesUploading = (args) => {
        console.log("filesUploading args");
        args.currentRequest.setRequestHeader('Authorization', `Bearer ${currentUser.aadResponse.jwtIdToken}`);
        args.customFormData = [{'fileGuid': fileGuid.find(f => f.fileName === args.fileData.name).fileGuid}]
    }
    
    const onChunkUpload = (args) => {
        console.log("chunk args", args);
        args.currentRequest.setRequestHeader('Authorization', `Bearer ${currentUser.aadResponse.jwtIdToken}`);
        args.customFormData = [{'fileGuid': fileGuid.find(f => f.fileName === args.fileData.name).fileGuid}]
    }

    const onSelect = (args) => {
        for(const file of args.filesData) {
            if(!fileGuid.some(f => f.fileName === file.name)) {
                fileGuid.push({fileName: file.name, fileGuid: uuidv4()})
            }
        }
    }

    const onActionComplete = (args) => {
        fileGuid.pop(f => f.fileName === args.fileData.name);
        setSnackBarOpen(true)
        setErrorSnackBarText("File has been uploaded and will be reviewed by an admin");
    }

    const onFailure = (args) => {
        setErrorSnackBarText("Unable to upload the file Please try again");
        setErrorSnackBarOpen(true);
    }

    const onClear = (args) => {
        fileGuid.pop(f => f.fileName === args.fileData.name);
    }

    return (
        <Container maxWidth={false} disableGutters={true} className={classes.root}>
            {(userCompanies && userCompanies.length === 0) &&
                <Typography variant="h5">You do not have access to any companies</Typography>
            }
            {(userCompanies && userCompanies.length > 0) ?
                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Grid item md={4} style={{ flex: 1 }}>
                        <div>
                            <Typography variant="h5" style={{ display: 'inline-block', paddingRight: '5px', marginTop: '15px' }}>Company:</Typography>
                            <Select
                                value={selectedCompany}
                                onChange={handleCompanyChange}
                            >
                                {
                                    userCompanies.map((item, index) => {
                                        return (
                                            <MenuItem value={item.company.id} key={index}>{item.company.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={6} className={classes.griditem}>
                        {uploadFiles &&
                            <UploaderComponent 
                                asyncSettings={asyncSettings} 
                                uploading={onFilesUploading}
                                maxFileSize={1073741824000}
                                chunkUploading={onChunkUpload}
                                selected={onSelect}
                                clearing={onClear}
                                removing={onClear}
                                actionComplete={onActionComplete}
                                onFailure={onFailure}
                            />
                            
                        }
                    </Grid>
                </Grid>
                :
                <Typography variant="subtitle1">No Companies Found</Typography>
            }
            <div style={{ paddingTop: '10px' }}>
                {/* <div id='droparea' style={{ height: '400px', border: '1px solid black' }}></div> */}
                {(!loadingFiles && !loadingCompanies) ?
                    companyFiles.length > 0 ?
                        <div className={classes.cards}>
                            {companyFiles.map((item, index) => {
                                return (
                                    <Grow in={true} key={index}>
                                        <Card className={`  ${classes.fileCard} ${item.downloading ? classes.cardLoading : classes.cardNotLoading}`} onClick={() => handleDownloadClick(item.id)}>
                                            <CardContent>
                                                <Typography variant="h6">{item.fileName}</Typography>
                                                <Typography variant="subtitle1">Added: {item.dateUploaded}</Typography>
                                                <Typography variant="subtitle1">Expires: {item.expirationDate}</Typography>
                                                {item.downloading &&
                                                    <CircularProgress style={{ margin: 0, padding: 0, marginLeft: '40%', marginBottom: '45%' }} />
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grow>
                                )
                            })
                            }
                        </div>
                        :
                        <Typography variant="subtitle1">No Files Available</Typography>
                    :
                    <Typography variant="subtitle1">Loading...</Typography>
                }
            </div>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="success">
                {errorSnackBarText}
                </Alert>
            </Snackbar>

            <Snackbar
                open={errorSnackBarOpen}
                autoHideDuration={5000}
                onClose={handleErrorSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleErrorSnackbarClose} severity="error">
                    {errorSnackBarText}
                </Alert>
            </Snackbar>


        </Container>
    );
}