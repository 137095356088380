const initialState = {
  user: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "CURRENT_USER":
      return {...state, user: action.payload}
    case 'AAD_LOGIN_FAILED':
      return {...state, aadResponse: null}
    case 'AAD_LOGIN_ERROR':
        return {...state, aadResponse: action.payload}
    case 'AAD_LOGIN_SUCCESS':
        return {...state, aadResponse: action.payload}
    case 'SET_TOKEN':
      return Object.assign({}, state, {
        jwtIdToken: Object.assign({}, state.aadResponse.jwtIdToken, action.payload)
      });
    default:
      return state;
  }
}
